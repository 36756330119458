/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:beeaceee-41e9-4c18-8d2f-3343e39fb3c5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_kHq0gunh0",
    "aws_user_pools_web_client_id": "6rafla53ia7kc71bv7akv6l3u4",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://3jyxcsteijaappfbd67v67reda.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
