/*******************************************************************************
  CreateSite.js

  Author: Advait

 This file  is responsible for creating new sites for Facilities.
 For initial phases, we also creating 14 schedule items for each site

 Current code is developed by newbie developer, so may not be standard
*******************************************************************************/
import React, {Component} from 'react';
import { createSite } from '../graphql/mutations'
import { createScheduleItem } from '../graphql/mutations'
import { getTenants } from '../apihelpers/graphqlhelpers'
import '../index.css'

import {API,graphqlOperation} from 'aws-amplify';

class CreateSite extends Component{

  constructor(props){
    super(props);
    this.state={
      id:"",
      sites:[],
      tenants:[],
      value:"",
      createSiteName:"",
      createRmsName:"",
      createMapName:"",
      selectedTenant:""
    };
    this.handleCreateSite = this.handleCreateSite.bind(this)
    this.handleChangeSiteName = this.handleChangeSiteName.bind(this)
    this.handleSelectedTenant = this.handleSelectedTenant.bind(this)
    this.handleChangeRmsName = this.handleChangeRmsName.bind(this)
    this.handleChangeMapName = this.handleChangeMapName.bind(this)
    this.handleCreateScheduleItems = this.handleCreateScheduleItems.bind(this)
    
  }
  async componentDidMount(){
    let tenants = await getTenants(false);
    this.setState({tenants:tenants});
    console.log("this.state.tenants", this.state.tenants)
  }

  async handleCreateSite(event){
    event.preventDefault()
    event.stopPropagation()
    if(this.state.selectedTenant===''){
      alert("Please select a tenant")
    }else if(this.state.createSiteName===''||this.state.createRmsName===''||this.state.createMapName===''){
      alert("Please enter all fields")
    }else{
      const site = {
        siteName:this.state.createSiteName,
        tid:this.state.selectedTenant,
        tenantSitesId:this.state.selectedTenant,
        rmsName:this.state.createRmsName,
        mapName:this.state.createMapName,
        alarmThresholds:'{"temperature": {"highAlarm":78, "highWarning":75, "lowWarning":65, "lowAlarm":60}, "humidity": {"highAlarm":80, "highWarning":60, "lowWarning":35, "lowAlarm":25}}',
        uvRestrictHours:false,
        buildingAlarmIntegrated:false,
        buildingAlarmState:"UV_ALLOWED",
        restrict_ui_page:'{"disinfection": false, "inspection": false, "security_patrol": false, "reception": false}',
        disabled: false,
        securityRound_action:"NONE",
        securityRound_wait_time:0,
      }
      try{
        const response = await API.graphql(graphqlOperation(createSite, {input:site}))
        console.log("response", response)
        console.log("id", response.data.createSite.id, "tid", response.data.createSite.tid)
        if(response!==''){
          this.handleCreateScheduleItems(response.data.createSite.id, response.data.createSite.tid)
          alert("Site created successfully.")
        }
      } catch(e){
        console.log(e)
      }
      this.setState({createSiteName:"", selectedTenant:""})
      this.setState({createRmsName:""})
      this.setState({createMapName:""})
    }
  }

  handleChangeSiteName(event){
    this.setState({createSiteName:event.target.value})
  }

  handleSelectedTenant(event){
    this.setState({selectedTenant:event.target.value})
  }

  handleChangeMapName(event){
    this.setState({createMapName:event.target.value})
  }

  handleChangeRmsName(event){
    this.setState({createRmsName:event.target.value})
  }
  //***************************************************************************
  // create 7 schedule for each mode.
  //***************************************************************************
  async handleCreateScheduleItems(siteID, tenantId){
    var i = 0;
    do {
      // First 7 schedules are for reception, next 7 are inspection, next 7 disinfection, next 7 security patrol
      // Only reception and security patrol has a duration
        let ScheduleItem = {
          siteId: siteID,
          enabled: false,
          day: (i % 7).toString(),
          startTime: "8:00",
          tid: tenantId
        }
      if(i < 7){
        ScheduleItem.type = "reception"
        ScheduleItem.duration = "5:00"
      }else if (i < 14){
        ScheduleItem.type = "inspection"
      }else if (i < 21){
        ScheduleItem.type = "disinfection"
      }else if((i < 28)){
        ScheduleItem.type = "disinfection_allowed"
        ScheduleItem.startTime = "00:30"
        ScheduleItem.duration = "2:00"
      }else if((i < 35)){
        ScheduleItem.type = "security_patrol"
        ScheduleItem.startTime = "00:30"
        ScheduleItem.duration = "4:00"
      }
      try{
        const response = await API.graphql(graphqlOperation(createScheduleItem, {input:ScheduleItem}))
        console.log("response", response)
        if(response!==''){
          console.log("schedule created successfully.")
        }
      } catch(e){
        console.log(e)
      }

      i++
    } while (i < 35)
  }

  render(){
      const alltenants = [].concat(this.state.tenants)
      .map((tenant,i)=>
        {
          return  <option key={i} value={tenant.id}>
                    {tenant.company}
                  </option>
        }
      )
      return (
           <div className="fullPageDiv" >
             <h3>Create Site for Tenant </h3>
             <div className="individualContainer">
               <label>Select a tenant to create their site</label>
               <br/>
               <select
                 value={this.state.selectedTenant}
                 onChange={this.handleSelectedTenant}
               >
                 <option value="">-- Please choose a tenant --</option>
                 {alltenants}
               </select>
               <hr/>
               <form onSubmit={this.handleCreateSite}>
                 <input type="text" placeholder="site name" value={this.state.createSiteName} onChange={this.handleChangeSiteName}/>
                 <hr />
                <input type="text" placeholder="RMS Name" value={this.state.createRmsName} onChange={this.handleChangeRmsName}/>
                <hr />
                <input type="text" placeholder="Map Name" value={this.state.createMapName} onChange={this.handleChangeMapName}/>
                <hr />
                <button className="startCreateButton" type="submit" style={{'width': 'fitContent !important'}} >Create Site</button>
              </form>
            </div>
           </div>
      )
    }
}

export default CreateSite
