/*******************************************************************************
  ShowDisinfectionAllowedHrs.js

  Author: Advait

*******************************************************************************/

import React, { Component } from 'react'
import ScheduleItemTable from './ScheduleItemTable'
import { updateUVRestrictHours } from '../apihelpers/graphqlhelpers'
import '../index.css'

class ShowDisinfectionAllowedHrs extends Component {
  constructor(props) {
    super(props)
    this.onSave = props.onSaved;  // callback when user saves changes
    this.state = {
      selectedSite: this.props.selectedSite,
      allowDisinfectionatAllHrs: this.props.allowDisinfectionatAllHrs // boolean value, if false then show retsricted times
    }
    this.handleChangeAllowedAllHrs = this.handleChangeAllowedAllHrs.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    // given by the parent in props.
    if (
      (this.state.selectedSite !== prevState.selectedSite) ||
      (this.props.allowDisinfectionatAllHrs !== prevProps.allowDisinfectionatAllHrs)) {
      this.setState({ selectedSite: this.props.selectedSite, allowDisinfectionatAllHrs: this.props.allowDisinfectionatAllHrs });
    }
  }

  //***************************************************************************
  async handleChangeAllowedAllHrs(e) {
    let updatedAllowDisinfectionatAllHrs = e.target.checked
    // As UI displays the allowed hrs, uvRestrictHr will be negative value of updatedAllowDisinfectionatAllHrs
    let uvRestrictHr = !(updatedAllowDisinfectionatAllHrs)
    await updateUVRestrictHours(this.state.selectedSite, uvRestrictHr);
    this.setState({ allowDisinfectionatAllHrs: updatedAllowDisinfectionatAllHrs});
    this.onSave(); // notify parent we saved new data
  }

  //***************************************************************************
  // render the UI
  //***************************************************************************

  render() {
    return (
      <div className="individualContainer">
        <h4>Allowed Disinfection Hours</h4>
        <div style={{ 'display': 'block' }}>
          <br />
          <input
            onChange={(e) => this.handleChangeAllowedAllHrs(e)}
            name="uvRestrictHours"
            type="checkbox"
            label="Allow Disinfection at all hours"
            checked={this.state.allowDisinfectionatAllHrs}
          />
          &nbsp; Allow Disinfection at All Hours
        </div>
        {!(this.state.allowDisinfectionatAllHrs) ? <ScheduleItemTable scheduleType="disinfection_allowed"
          selectedSite={this.state.selectedSite} /> : null}
      </div>
    )
  }
}
export default ShowDisinfectionAllowedHrs