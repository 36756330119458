/*******************************************************************************
  UVLightState.js

  Author: Advait

*******************************************************************************/

import React from 'react';

/**
   * UVLightState() - safely get the UV Light status for the robot,
   * return "On", "Off", "Not Installed" or "" if robor is disconnected.
  */
function UVLightState(props){
  
  let siteResponse = props.siteResponse
  var uv_lights = null
  if ((siteResponse)&&(siteResponse.shadowstate)){
    if (siteResponse.shadowstate.uv_lights && 
      siteResponse.shadowstate.uv_lights.state){
        if (siteResponse.shadowstate.uv_lights.state === "ON"){
          uv_lights = "On"
        }else if (siteResponse.shadowstate.uv_lights.state === "OFF"){
          uv_lights = "Off"
        } else if (siteResponse.shadowstate.uv_lights.state === "NOT_INSTALLED"){
          uv_lights = "Not Installed";
        }
      } else {
        uv_lights = "Not Installed";
      }
  }
  return(
   <span>{uv_lights}</span>
  )
}
export default UVLightState