/*******************************************************************************
  LocationBehavior.js

  Author: Advait
*******************************************************************************/

import React, {Component} from 'react'
import '../index.css'
import { updateLocationBehavior } from '../apihelpers/graphqlhelpers'

const actions = [
  { key: 'No action', value: 'NONE' },
  { key: 'Rotate 360 degrees', value: '360' }
];


//***************************************************************************
// This class handles location behavior values.
// Initial values to display come from props.
// Parent can change props to update the display with new values.
// When user save the changes, notify the parent.
//***************************************************************************
class LocationBehavior extends Component{
  constructor(props){
    super(props)
    this.state={
      securityRound_action:this.props.securityRound_action || "NONE",
      securityRound_wait_time:this.props.securityRound_wait_time || 0
    }
    this.handleSaveBehavior = this.handleSaveBehavior.bind(this)
    this.handleUpdateBehaviorValues = this.handleUpdateBehaviorValues.bind(this)  
  }
  
  componentWillUnmount(){
    this.setState({
      securityRound_action:"NONE",
      securityRound_wait_time:0
    })
  }

  //***************************************************************************
  // If the parent changes values in props, update UI with new values
  //***************************************************************************
  componentDidUpdate(prevProps) {
    if(this.props !== prevProps) {
      this.setState({
        securityRound_action:this.props.securityRound_action||"NONE",
        securityRound_wait_time:this.props.securityRound_wait_time||0
      });
    }
  }
  //***************************************************************************
  // Update the values to the database and notify parent on save button
  //***************************************************************************
  async handleSaveBehavior(e){

    await updateLocationBehavior(this.props.selectedSite, this.state.securityRound_action, this.state.securityRound_wait_time);
    // notify parent to save
    this.props.onSaved(); // notify parent we saved new data
  }
  //***************************************************************************
  // Update the state with respect to the input by the user
  //***************************************************************************
  async handleUpdateBehaviorValues(e, variable){
    if(variable === "action"){
      this.setState({securityRound_action:e.target.value});
    }
    if(variable === "time"){
      this.setState({securityRound_wait_time:e.target.value});
    }
  }
  //***************************************************************************
  // render the UI
  //***************************************************************************
  render(){

    return (
      <div>
        <div className="individualContainer">
          <h4>Location Behavior <br/></h4>
          <table className = "loactionBehaviorTable">
            <thead>
              <tr>
                <td style={{verticalAlign: 'baseline'}}>Action <br/>
                <label style = {{fontSize:'medium'}}>
                  (Action to take at each <br/> location) 
                </label>
                  
                </td>
                <td style={{verticalAlign: 'baseline'}}>Wait Time <br/>
                  <label style = {{fontSize:'medium'}}>
                    (Time to wait at each location <br/> after performing the action)
                  </label>
                </td>  

              </tr>
            </thead>
            <tbody>
              <tr >
                <td>
                  <select id="action" name="action" className = "centerInput" value={this.state.securityRound_action} onChange={(e) => this.handleUpdateBehaviorValues(e, "action")}>
                      {actions.map((action) => (
                          <option key={action.value} value={action.value}>
                              {action.key}
                          </option>
                      ))}
                  </select>
                </td>
                <td>
                  <input className = "centerInput" type="number" min={0} max={999} value={this.state.securityRound_wait_time} onChange={(e) => this.handleUpdateBehaviorValues(e, "time")}/>  
                  &ensp;second(s)
                </td>
                
              </tr>
            </tbody>
          </table>
          <br />
          <button onClick={this.handleSaveBehavior} >Save</button>
        </div>
        <br/>
      </div>
    )
  }   
}
export default LocationBehavior

