/*******************************************************************************
  SiteStatus.js

  Author: Mark Duckworth;

*******************************************************************************/

import React, {Component} from 'react'
import {PollSiteStatus} from '../apihelpers/poller'
import {getBuildingAlarmStatus} from '../apihelpers/apihelpers'
import UVLightState from './UVLightState'

const POLL_PERIOD = 20000;

/**
 * SiteStatus class shows basic status of a single Facilities site
 * The siteid and sitename must be given in props.
 * This class polls the task manager API to get site status, and it 
 * displays the status in a simple table.
 */
class SiteStatus extends Component{
  constructor(props){
    super(props)
    this.state={
      siteStatus:{},
    }
    this.statusPoller = new PollSiteStatus(this);
  }

  async componentDidMount(){
    this.statusPoller.startPoll(0, POLL_PERIOD, this.props.siteid);
  }

  componentWillUnmount(){
    this.statusPoller.stopPoll();
  }

  //***************************************************************************
  // Callback from the status poller.
  // siteResponse - object response from the GET /site/<id> API
  //***************************************************************************
  siteStatusCb(siteResponse){
    this.setState({siteStatus:siteResponse});
  }


/**
 * getDocked() - safely get the docked status for the robot,
 * return "Yes", "No", or "" if there is no status.
 */
  getDocked() {
    let docked = "";
    if (this.state.siteStatus &&
        this.state.siteStatus.robotinfo &&
        this.state.siteStatus.robotinfo.health &&
        this.state.siteStatus.robotinfo.health.PowerStatus &&
        this.state.siteStatus.robotinfo.health.PowerStatus.DockSenseBit) {
      if (this.state.siteStatus.robotinfo.health.PowerStatus.DockSenseBit === "1") {
        docked = "Yes";
      } else {
        docked = "No";
      }
    }
    return docked;
  }

/**
 * getStopButton() - safely get the EStop button status for the robot,
 * return "Pressed", "Normal", or "" if there is no status.
 */
  getStopButton() {
    let estop = "";
    if (this.state.siteStatus &&
        this.state.siteStatus.robotinfo &&
        this.state.siteStatus.robotinfo.health &&
        this.state.siteStatus.robotinfo.health.PowerStatus &&
        this.state.siteStatus.robotinfo.health.PowerStatus.Estop) {
      if (this.state.siteStatus.robotinfo.health.PowerStatus.Estop === "1") {
        estop = "Pressed";
      } else {
        estop = "Normal";
      }
    }
    return estop;
  }

/**
 * getRobotLink() - get the robot ID and URL link to RMS
 */
  getRobotLink() {
    let robotLink = "Disconnected";
    let connected = false;
    if (this.state.siteStatus &&
        this.state.siteStatus.robotinfo &&
        this.state.siteStatus.robotinfo.status &&
        this.state.siteStatus.robotinfo.status.connected) {
        connected = true;
    }
    if (this.state.siteStatus && this.state.siteStatus.robotid) {
        const href = `https://${this.props.rmsname}/cust_admin/application/robots/detail.html?robot=${this.state.siteStatus.robotid}`;
        robotLink = <span><a href={href} target="_blank" rel="noopener noreferrer">
                      {this.state.siteStatus.robotid}
                    </a>
                    {!connected?" disconnected":""}</span>;
    }
    return robotLink;
  }

  render(){
      return (
               <div>
                  <h3 >
                    {this.props.sitename} 
                    <br/>
                    {this.props.sysadmin ? <span className="status-label" style = {{fontSize:'initial'}}>(Site Id: {this.props.siteid})</span> : null}
                  </h3>
                  <table>
                    <tbody>
                        <tr>
                          <td>
                            <ul className="list-status">
                              <li>
                                <span className="status-label">Robot ID: </span>
                                <span>{this.getRobotLink()}</span>
                              </li>
                              <li>
                                <span className="status-label">Mode: </span>
                                <span>{this.state.siteStatus && this.state.siteStatus.facilitiesState}</span>
                              </li>
                              <li>
                                <span className="status-label">Robot Health: </span>
                                <span>{this.state.siteStatus &&
                                        this.state.siteStatus.robotinfo &&
                                        this.state.siteStatus.robotinfo.health &&
                                        this.state.siteStatus.robotinfo.health.overallHealth}</span>
                              </li>
                              <li>
                                <span className="status-label">UV Light: </span>
                                <span><UVLightState siteResponse = {this.state.siteStatus} /></span>
                              </li>
                            </ul>
                          </td>
                          <td>
                            <ul className="list-status">
                              <li>
                                <span className="status-label">Docked: </span>
                                <span>{this.getDocked()}</span>
                              </li>
                              <li>
                                <span className="status-label">Stop Button: </span>
                                <span>{this.getStopButton()}</span>
                              </li>
                              <li>
                                <span className="status-label">Battery Charge: </span>
                                <span>{this.state.siteStatus && 
                                        this.state.siteStatus.robotinfo &&
                                        this.state.siteStatus.robotinfo.health &&
                                        (Math.round(parseFloat(this.state.siteStatus.robotinfo.health.batteryCharge))).toString()+'%'}</span>
                              </li>
                              <li>
                                <span className="status-label">Building Alarm: </span>
                                <span>{getBuildingAlarmStatus(this.state.siteStatus)}</span>
                              </li>
                            </ul>
                          </td>
                        </tr>
                    </tbody>
                  </table>
               </div>
      )
  }   
}
export default SiteStatus