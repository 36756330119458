/*******************************************************************************
  SystemConfig.js

  Author: Advait; Mark Duckworth;
*******************************************************************************/
import React, { Component } from 'react'
import SelectSite from '../components/SelectSite'

class SystemConfig extends Component {

  render() {
    return (
      <div className="fullPageDiv">
        <h3>System Configuration</h3>
        <SelectSite pageType ="sysConfig"/>
      </div>
    )
  }
}

export default SystemConfig
