import {API} from 'aws-amplify'

// Configure the base URL for the fataskman service.
// This value comes from Amazon API Gateway, which links this URL to 
// the actual service running in a Docker container.
function getTaskmanUrl() {
    var taskmanUrl = process.env.REACT_APP_TASKMAN_URL;
    if (!taskmanUrl) {
        taskmanUrl = "https://ha802343db.execute-api.us-east-1.amazonaws.com/dev"; // default value
    }
    return taskmanUrl;
}

//***************************************************************************
// siteDoAction - send an action command to a site
// returns a Promise with the response from the REST API
//***************************************************************************
function siteDoAction(site, action){
console.log(`siteDoAction() ${action}, ${site}`)
let apiName = 'fataskman';

return new Promise((resolve, reject) => {
    API.post(apiName, '/sites/' + site, {body:{"action":action}}).then(response => {
    console.log(apiName+" REST RESPONSE: " + JSON.stringify(response));
    if(response.result==='success'){
        resolve(response);
    }else{
        reject(response);
    }
    }).catch(error => {
        console.log(apiName+" REST ERROR: ", error.response);
        reject(error.response);
    });
});
}

/**
 * Get a displayable text string for the site's alarm status
 * @param {object} siteResponse from API GET /site/<siteid>
 * @return {string} alarm status
 */
function getBuildingAlarmStatus(siteResponse) {
    let statusText = "Not Using an Alarm";
    const alarmToText = { //convert state machine state to display text
        'UV_ALLOWED': 'Allowed',
        'UV_NOT_ALLOWED': 'Blocked',
    }

    if (siteResponse && siteResponse.siteinfo && siteResponse.siteinfo.building_alarm_integrated){
        statusText = alarmToText[siteResponse.siteinfo.building_alarm_state];
        if (!statusText) {
            statusText = siteResponse.siteinfo.building_alarm_state;
        }
    }
    return statusText;
}

export {getTaskmanUrl, siteDoAction, getBuildingAlarmStatus}