/*******************************************************************************
  Home.js

  Author: Advait

*******************************************************************************/
import React, {Component} from 'react'
import SiteStatus from '../components/SiteStatus'
import { getTenantsWithSites } from '../apihelpers/graphqlhelpers'
import '../index.css'

class Home extends Component{
  constructor(props){
    super(props);
    this.state={
      //id:"",
      company:"",
      tenants:[],
      sites:[],
      value:"",
    };
  }
  async componentDidMount(){
    //this is called once the page is load
    //it wont be called again if something changes on the page

    let tenants = await getTenantsWithSites(false); //TODO add a way to see disabled tenants
    this.setState({tenants:tenants});
    console.log("list of tenants",this.state.tenants)
    // store tenant to local database so user(non-sysadmin) will not see
    // a dialog box to select tenant on other pages
    var count = Object.keys(tenants).length;
    if(count === 1){
      localStorage.setItem('selectedTenant', tenants[0].id);
    }
  }

  render(){
    const alltenantdata = [].concat(this.state.tenants)
    .map((tenant,i)=>
    <div key ={i}>
      <h1 style={{lineHeight:'0.8em'}}>
        {tenant.company} 
        <br/>
        {this.props.sysadmin ? <span className="status-label" style = {{fontSize:'initial'}}>(Tenant Id: {tenant.id})</span> : null}
      </h1>
      <div style = {{paddingTop:'2%'}}>
          {// sort sites based on siteName
            (tenant.sites.items.sort((a, b) => a.siteName.toLowerCase() > b.siteName.toLowerCase() ? 1 : -1))
          .map((site,i)=>
          <SiteStatus key={i} siteid={site.id} sitename={site.siteName} rmsname={site.rmsName} sysadmin={this.props.sysadmin}/>
          )}
      </div>
    </div>
    )
    return (
       <div className="fullPageDiv">
         {alltenantdata}
       </div>
    )
  }
}
export default Home
