/*******************************************************************************
  Reporting.js

  Author: Advait
*******************************************************************************/
import React, { Component } from 'react'
import SelectSite from '../components/SelectSite'
import '../index.css'

/**
 * Page for getting reports about a facilities site
 */
class Reporting extends Component {
  render() {
    return (
      <div className="fullPageDiv">
        <h3>Reporting</h3>
        <SelectSite pageType="reporting" />
      </div>
    )
  }
}
export default Reporting
