/*******************************************************************************
  CreateTenant.js

  Author: Advait

 This file  is responsible for creating new tenants for Facilities.

 Current code is developed by newbie developer, so may not be standard
*******************************************************************************/
import React, {Component} from 'react';
import {createTenant} from '../graphql/mutations'
import {API,graphqlOperation} from 'aws-amplify';

class CreateTenant extends Component{

  constructor(props){
    super(props);
    this.state={
      id:"",
      createCompany:"",
      tenants:[],
    };
    this.handleCreateTenant = this.handleCreateTenant.bind(this)
    this.handleChangeCompany = this.handleChangeCompany.bind(this)
  }

  async handleCreateTenant(event){
    console.log("name of company : ", this.state.createCompany)
    event.preventDefault()
    event.stopPropagation()
    if(this.state.createCompany===''){
      alert("Please enter tenant name")
    }else{
      try{
        const tenant = {
          company:this.state.createCompany,
          disabled: false,
        }
        const response = await API.graphql(graphqlOperation(createTenant, {input:tenant}))
        this.setState({createCompany:""})
        if(response!==''){
          alert("Tenant created successfully.")
        }
      } catch(e){
        console.log(e)
      }

    }
  }

  handleChangeCompany(event){
    this.setState({createCompany:event.target.value})
  }

  render(){
      return (
        <div className="fullPageDiv" >
          <h3> Create Tenant</h3>
          <div className="individualContainer">
            <form onSubmit={this.handleCreateTenant}>
              <input type="text" placeholder="company name" onChange={this.handleChangeCompany}/>
              <hr/>
              <button className="startCreateButton" type="submit">Create Tenant</button>
            </form>
          </div>
        </div>
      )
  }
}

export default CreateTenant
