/*******************************************************************************
  SiteConfigForm.js

  Author: Mark Duckworth; Advait;
*******************************************************************************/
import React, {Component} from 'react'

import '../index.css'

/**
 * This component is a form for editing Site object field values.
 * This follows the "controlled components" pattern in React.
 * https://reactjs.org/docs/forms.html#controlled-components
 */
class SiteConfigForm extends Component{
  constructor(props){
    super(props)
    //this.onSave = props.onSave;  // callback when user saves changes
    this.state = {
      sysAdmin: props.sysAdmin,
      siteid: props.siteid,
      emailsToNotify: props.emailsToNotify,
      adminEmailsToNotify: props.adminEmailsToNotify,
      rmsName: props.rmsName,
      rmsUser: props.rmsUser,
      rmsPw: props.rmsPw,
      mapName: props.mapName,
      siteName: props.siteName,
      timezone: props.timezone,
      recharge_threshold_min: props.recharge_threshold_min,
      recharge_threshold_max: props.recharge_threshold_max,
      restrict_ui_page:props.restrict_ui_page
    }
    this.onSave = props.onSave;//.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRestrictUI = this.handleRestrictUI.bind(this);
    this.verifyRestrictUIPageFields = this.verifyRestrictUIPageFields.bind(this);
    //this.AdminEmails = this.AdminEmails.bind(this);
    console.log("SCF1-emails: "+this.state.emailsToNotify+"; admin: "+this.state.adminEmailsToNotify);
  }

  componentDidUpdate(prevProps) {
    // given by the parent in props. Each field on the form must be included here.
    if (this.props !== prevProps) {
      this.setState({
          emailsToNotify: this.props.emailsToNotify, 
          siteid: this.props.siteid,
          adminEmailsToNotify: this.props.adminEmailsToNotify,
          rmsName: this.props.rmsName,
          rmsUser: this.props.rmsUser,
          rmsPw: this.props.rmsPw,
          mapName: this.props.mapName,
          siteName: this.props.siteName,
          timezone: this.props.timezone,
          recharge_threshold_min: this.props.recharge_threshold_min,
          recharge_threshold_max: this.props.recharge_threshold_max,
          restrict_ui_page:this.props.restrict_ui_page
        });
      // TODO: Update this to include other fields
    }

  }

  // keep state up to date whenever the user edits values in the form
  handleInputChange(event) {
    const target = event.target;
    this.setState({[target.name]: target.value});
  }
  
  verifyRestrictUIPageFields(){
    var new_restrict_ui = {
      'disinfection':false,
      'inspection': false,
      'reception': false,
      'security_patrol': false
    }

    let restrctui = this.state.restrict_ui_page
    
    if (restrctui){
      if (restrctui.hasOwnProperty('disinfection') && restrctui.disinfection){
        new_restrict_ui.disinfection = restrctui.disinfection
      }
      if (restrctui.hasOwnProperty('inspection') && restrctui.inspection){
        new_restrict_ui.inspection = restrctui.inspection
      }
      if (restrctui.hasOwnProperty('reception') && restrctui.reception){
        new_restrict_ui.reception = restrctui.reception
      }
      if (restrctui.hasOwnProperty('security_patrol') && restrctui.security_patrol){
        new_restrict_ui.security_patrol = restrctui.security_patrol
      }
    }
    this.setState({restrict_ui_page:new_restrict_ui })
    return new_restrict_ui;

  }

  handleRestrictUI(e, uiPage){
    var new_restrictui = this.verifyRestrictUIPageFields();
    if(uiPage === "disinfection"){
      new_restrictui['disinfection'] = e.target.checked
    }
    if(uiPage === "inspection"){
      new_restrictui['inspection'] = e.target.checked
    }
    if(uiPage === "reception"){
      new_restrictui['reception'] = e.target.checked
    }
    if(uiPage === "security_patrol"){
      new_restrictui['security_patrol'] = e.target.checked
    }
    this.setState({restrict_ui_page:new_restrictui})
  }
  
  // user clicked the save button
  async handleSubmit(event) {
    event.preventDefault();
    this.onSave(this.state); // notify parent we saved new data
  }

  // sysadmin config section
  AdminSection() {
    

    const restrict_ui = this.state.restrict_ui_page
    const hideDisinfectionPage = (restrict_ui && (restrict_ui.hasOwnProperty("disinfection")) && restrict_ui["disinfection"] === true ) ? true : false
    const hideInspectionPage = (restrict_ui && (restrict_ui.hasOwnProperty("inspection")) && restrict_ui["inspection"] === true ) ? true : false
    const hideReceptionPage = (restrict_ui && (restrict_ui.hasOwnProperty("reception")) && restrict_ui["reception"] === true ) ? true : false
    const hideSecurityPatrolPage = (restrict_ui && (restrict_ui.hasOwnProperty("security_patrol")) && restrict_ui["security_patrol"] === true ) ? true : false
    return(
    <tbody>
        <tr>
          <td><h6>Sysadmin emails</h6></td>
          <td>
            <label>
                <input className = "centerInput"
                type="text"
                name="adminEmailsToNotify"
                size="40"
                value={this.state.adminEmailsToNotify}
                onChange={this.handleInputChange}/>
            </label></td></tr>
        <tr className="spaceOver"><td colSpan="2"><h4>RMS Information</h4></td></tr>
        <tr>
            <td><h6>Web Address</h6></td>
            <td><input className="centerInput"
            type="text"
            name="rmsName"
            size="40"
            value={this.state.rmsName ? this.state.rmsName : "<rms>.ava8.net"}
            onChange={this.handleInputChange}
            ></input></td>
        </tr>
        <tr>
            <td><h6>Username</h6></td>
            <td><input className="centerInput"
            type="text"
            name="rmsUser"
            size="40"
            value={this.state.rmsUser}
            onChange={this.handleInputChange}
            ></input></td>
        </tr>
        <tr>
            <td><h6>Password</h6></td>
            <td><input className="centerInput"
            type="password"
            name="rmsPw"
            size="40"
            value={this.state.rmsPw}
            onChange={this.handleInputChange}
            ></input></td>
        </tr>
        <tr>
            <td><h6>Map Name</h6></td>
            <td><input className="centerInput"
            type="text"
            name="mapName"
            size="40"
            value={this.state.mapName}
            onChange={this.handleInputChange}
            ></input></td>
        </tr>
        <tr className="spaceOver"><td colSpan="2"><h4>Other Facilities Settings</h4></td></tr>
        <tr>
            <td><h6>Site Name</h6></td>
            <td><input className="centerInput"
            type="text"
            name="siteName"
            size="40"
            value={this.state.siteName}
            onChange={this.handleInputChange}
            ></input></td>
        </tr>
        <tr>
            <td><h6><a href="https://docs.aws.amazon.com/redshift/latest/dg/time-zone-names.html">Timezone</a></h6></td>
            <td><input className="centerInput"
            type="text"
            name="timezone"
            size="40"
            value={this.state.timezone}
            onChange={this.handleInputChange}
            ></input></td>
        </tr>
        <tr>
            <td><h6>Min Recharge</h6></td>
            <td><input className="centerInput"
            type="number"
            name="recharge_threshold_min"
            size="40"
            value={this.state.recharge_threshold_min}
            onChange={this.handleInputChange}
            ></input></td>
        </tr>
        <tr>
            <td><h6>Max Recharge</h6></td>
            <td><input className="centerInput"
            type="number"
            name="recharge_threshold_max"
            size="40"
            value={this.state.recharge_threshold_max}
            onChange={this.handleInputChange}
            ></input></td>
        </tr>
        <tr className="spaceOver"><td colSpan="2"><h4>Restrict UI</h4></td></tr>
        <tr>
            <td><h6>Restrict Disinfection UI </h6></td>
            <td><input
            onChange={(e) => this.handleRestrictUI(e, "disinfection")}
            name="restrictDisinfectionUI"
            type="checkbox"
            label="Restrict Disinfection UI"
            checked={hideDisinfectionPage}
            /></td>
            
        </tr>
        <tr>
            <td><h6>Restrict Inspection UI</h6></td>
            <td><input
            onChange={(e) => this.handleRestrictUI(e, "inspection")}
            name="restrictInspectionUI"
            type="checkbox"
            label="Restrict Inspection UI"
            checked={hideInspectionPage}
            /></td>
            
        </tr>
        <tr>
            <td><h6>Restrict Reception UI </h6></td>
            <td><input
            onChange={(e) => this.handleRestrictUI(e, "reception")}
            name="retrictReceptionUI"
            type="checkbox"
            label="Restrict Reception UI"
            checked={hideReceptionPage}
            /></td>
        </tr>
        <tr>
            <td><h6>Restrict Security Patrol UI </h6></td>
            <td><input
            onChange={(e) => this.handleRestrictUI(e, "security_patrol")}
            name="restrictSecurityPatrolUI"
            type="checkbox"
            label="Restrict Security Patrol UI"
            checked={hideSecurityPatrolPage}
            /></td>
        </tr>

    </tbody>
  )}

  render(){
    return (
      <div className="individualContainer">
        {console.log(this.state.emailsToNotify)}
        <form onSubmit={this.handleSubmit}>
        <table>
          <thead><tr><th colSpan="2"><h4>Emails for Notifications</h4></th></tr>
            <tr>
              <td><h6>Addresses</h6></td>
              <td>
              <label>
                <input className = "centerInput"
                type="text"
                name="emailsToNotify"
                size="40"
                value={this.state.emailsToNotify}
                onChange={this.handleInputChange}/>
              </label></td>
            </tr>
            <tr>
              <td colSpan="2"><h6>(Enter multiple emails comma-separated if applicable)</h6></td>
            </tr></thead>
          {/* <tbody> */}
          {/*TO DO add more fields like site name and timezone*/}
          {this.props.sysAdmin ? this.AdminSection() : null}
          {/* </tbody> */}</table>
          <input className="saveButton" type="submit" value="Save" />
        </form>
      </div>
    )
  }
}
export default SiteConfigForm
