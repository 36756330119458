/*******************************************************************************
  SiteConfigSection.js

  Author: Advait

*******************************************************************************/

import React, { Component } from 'react'
import { API } from 'aws-amplify'
import SiteConfigForm from './SiteConfigForm'
import { updateSite } from '../apihelpers/graphqlhelpers'
import '../index.css'

//***************************************************************************
// This class handles a notification email configurations.
// Initial values to display come from props.
// Parent can change props to update the display with new values.
//***************************************************************************
class SiteConfigSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //siteid: props.siteid,
      //sysAdmin: props.sysAdmin,
      emailsToNotify: props.emailsToNotify,
      adminEmailsToNotify: props.adminEmailsToNotify,
      rmsName: props.rmsName,
      rmsUser: props.rmsUser,
      rmsPw: props.rmsPw,
      mapName: props.mapName,
      siteName: props.siteName,
      timezone: props.timezone,
      recharge_threshold_min: props.recharge_threshold_min,
      recharge_threshold_max: props.recharge_threshold_max,
      restrict_ui_page:props.restrict_ui_page
    };
    console.log("SITECONFIGSECTION1-emails: "+this.state.emailsToNotify+"; admin: "+this.state.adminEmailsToNotify);
    this.onSave = props.onSave; //.bind(this);  // callback when user saves changes
    this.handleFormSave = this.handleFormSave.bind(this);
  }

  componentDidMount() {
      this.setState({emailsToNotify: this.props.emailsToNotify,
        adminEmailsToNotify: this.props.adminEmailsToNotify,})
  }

  componentDidUpdate(prevProps) {
    // When parent changes values, re-initialize to the values
    // given by the parent in props.
    // if (this.props.emailsToNotify !== prevProps.emailsToNotify) {
    //   this.setState({emailsToNotify: this.props.emailsToNotify, siteid: this.props.siteid});
    // }
    // if (this.props.adminEmailsToNotify !== prevProps.adminEmailsToNotify) {
    //   console.log("updating to: "+this.props.adminEmailsToNotify);
    //   this.setState({adminEmailsToNotify: this.props.adminEmailsToNotify});
    // }
    //TODO: Add the other components
    if (this.props !== prevProps) {
        console.log("SCS: updating site")
        this.setState(this.props)
    }
  }

  /**
   * called when user clicks Save in the form
   */
  async handleFormSave(fields) {
    // TODO: ADD THE OTHER FIELDS, EDIT EXISTING NAMES, RENAME FUNCTION
    this.setState({
        emailsToNotify: fields.emailsToNotify,
        adminEmailsToNotify: fields.adminEmailsToNotify,
        rmsName: fields.rmsName,
        rmsUser: fields.rmsUser,
        rmsPw: fields.rmsPw,
        mapName: fields.mapName,
        siteName: fields.siteName,
        timezone: fields.timezone,
        recharge_threshold_min: fields.recharge_threshold_min,
        recharge_threshold_max: fields.recharge_threshold_max,
        restrict_ui_page:fields.restrict_ui_page
    });

    // Update email address
    await updateSite(
        this.props.sysAdmin,
        fields
        );             
    
    // if time zone changed, tell task manager it should update the schedule
    if (this.props.timezone !== fields.timezone) {
        API.post('fataskman', '/sites/' + this.props.siteid, { body: { action: "update_schedule" } })
        .catch((err)=> console.log(err));
    }
    this.onSave(); // notify parent we saved new data
  }

  //***************************************************************************
  // render the UI
  //***************************************************************************
  render() {
    return (
      <div>
        {console.log("SITECONFIGSECTION2-emails: "+this.state.emailsToNotify+"; admin: "+this.state.adminEmailsToNotify)}
        <SiteConfigForm
            siteid={this.props.siteid}    
            sysAdmin={this.props.sysAdmin}
            emailsToNotify={this.state.emailsToNotify}
            adminEmailsToNotify={this.state.adminEmailsToNotify}
            rmsName={this.state.rmsName}
            rmsUser={this.state.rmsUser}
            rmsPw={this.state.rmsPw}
            mapName={this.state.mapName}
            siteName={this.state.siteName}
            timezone={this.state.timezone}
            recharge_threshold_min={this.state.recharge_threshold_min}
            recharge_threshold_max={this.state.recharge_threshold_max}
            restrict_ui_page={this.state.restrict_ui_page}
            onSave={this.handleFormSave}
        />
      </div>
    )
  }
}
export default SiteConfigSection