/*******************************************************************************
  DisinfectionConfig.js

  Author: Advait and Mark

*******************************************************************************/
import React, {Component} from 'react'
import SelectSite from '../components/SelectSite'
import '../index.css'

class DisinfectionConfig extends Component{
  render(){
    return (
      <div className="fullPageDiv">
        <h3>Site Disinfection Mode Configuration</h3>
        
        <SelectSite pageType ="roundConfig" scheduleType ="disinfection"/>
      </div>
    )
  }
}
export default DisinfectionConfig
