/*******************************************************************************
  AlarmConfig.js

  Author: Advait

*******************************************************************************/

import React, {Component} from 'react'
import '../index.css'

const defaultThresholds = {
  highAlarm: "",
  highWarning: "",
  lowWarning: "",
  lowAlarm:""
};

//***************************************************************************
// This class handles one set of alarm and warning thresholds, four values total.
// Initial values to display come from props.
// Parent can change props to update the display with new values.
// When user wants to save changes, notify the parent and let the parent
// handle persisting the changes.
//***************************************************************************
class AlarmConfig extends Component{
  constructor(props){
    super(props)
    this.state={
      alarmThreshold:this.props.values||defaultThresholds, //used for storing the updated value
      alarmType: this.props.alarmType
    }
    this.handleSaveThreshold = this.handleSaveThreshold.bind(this)
    this.handleUpdateThresholdValues = this.handleUpdateThresholdValues.bind(this)
  }
  
  componentWillUnmount(){
    this.setState({alarmThreshold:{}})
  }

  //***************************************************************************
  // If the parent changes values in props, update UI with new values
  //***************************************************************************
  componentDidUpdate(prevProps) {
    if(this.props !== prevProps) {
      this.setState({alarmThreshold:this.props.values||defaultThresholds});
    }
  }
  //***************************************************************************
  // Validate input and notify parent on save button
  //***************************************************************************
  async handleSaveThreshold(e){

    //to avoid page refresh on form action
    e.preventDefault();
    const threshold = this.state.alarmThreshold
    
    //check whether the entered value is greater than or equals to the lower value
    //for ex. high alarm value should be greater than or equal to high warning or it should be null

    let correctOrderofValues = false
    
    let highestValue  = (threshold.highAlarm===""||threshold.highAlarm===null)? 99 : threshold.highAlarm
    let highValue = (threshold.highWarning===""||threshold.highWarning===null)? highestValue : threshold.highWarning
    let lowValue = (threshold.lowWarning===""||threshold.lowWarning===null)? highValue : threshold.lowWarning
    let lowestValue = (threshold.lowAlarm===""||threshold.lowAlarm===null)? lowValue : threshold.lowAlarm

    threshold.highAlarm = (threshold.highAlarm==="")? null : threshold.highAlarm
    threshold.highWarning = (threshold.highWarning==="")? null : threshold.highWarning
    threshold.lowWarning = (threshold.lowWarning==="")? null : threshold.lowWarning
    threshold.lowAlarm = (threshold.lowAlarm==="")? null : threshold.lowAlarm
    //check the for the input values, values should be greater than or equals to the smaller value.
    if(highValue<=highestValue){
      if(lowValue<=highValue){
        if(lowestValue<=lowValue){
          correctOrderofValues = true
        }else{
          alert("Low warning value should be greater than or eqauls to the value of low alarm")
        }
      }else{
        alert("High warning value should be greater than or eqauls to the value of low warning")
      }
    }else{
      alert("High Alarm value should be greater than or eqauls to the value of high warning")
    }

    //if the order of the input values is correct then notify parent to save
    if (correctOrderofValues){
      this.props.onSave(this.state.alarmType, threshold);
    }
  }
  //***************************************************************************
  // Update the state with respect to the input by the user
  //***************************************************************************
  async handleUpdateThresholdValues(e, variable){

    let newThreshold = this.state.alarmThreshold
    if(variable === "highAlarm"){
      newThreshold.highAlarm = e.target.value
    }
    if(variable === "highWarning"){
      newThreshold.highWarning = e.target.value
    }
    if(variable === "lowWarning"){
      newThreshold.lowWarning = e.target.value
    }
    if(variable === "lowAlarm"){
      newThreshold.lowAlarm = e.target.value
    }
    this.setState({alarmThreshold:newThreshold})
  }
  //***************************************************************************
  // render the UI
  //***************************************************************************
  render(){
    const type= (this.state.alarmType==="temperature")? "Temperature":"Humidity"
    const min = (type==="Temperature")? "40":"0"
    const max = "99"
    /*This can be changed later, for now I am using either degree F or % */
    const unit = (type==="Temperature")? <label>&#8457;</label>:"%"
    const threshold = this.state.alarmThreshold
    return (
      <div>
      <div className="individualContainer">
        <h4>
          {type} Threshold <br/>
          <label style = {{fontSize:'medium'}}>
          {(type==="Temperature")? "* all the values must be in Fahrenheit and between 40-99":""}
          {(type==="Humidity")? "* all the values must be in %":""}
          </label>
        </h4>
        <form onSubmit = {this.handleSaveThreshold}>
        <table className = "individualContainerTable">
          <thead>
            <tr>
              <td>High Alarm</td>
              <td>High Warning</td>
              <td>Low Warning</td>
              <td>Low Alarm</td>
            </tr>
          </thead>
          <tbody>
            <tr >
              <td>
                <input className = "centerInput" type="number" min={min} max={max} value={threshold.highAlarm || ""} onChange={(e) => this.handleUpdateThresholdValues(e, "highAlarm")}/>  
                &ensp;{unit}
              </td>
              <td>
                <input className = "centerInput" type="number" min={min} max={max} value={threshold.highWarning || ""} onChange={(e) => this.handleUpdateThresholdValues(e, "highWarning")}/>
                &ensp;{unit}
              </td>
              <td>
                <input className = "centerInput" type="number" min={min} max={max} value={threshold.lowWarning || ""}  onChange={(e) => this.handleUpdateThresholdValues(e, "lowWarning")}/>
                &ensp;{unit}
              </td>
              <td>
                <input className = "centerInput" type="number" min={min} max={max} value={threshold.lowAlarm || ""}  onChange={(e) => this.handleUpdateThresholdValues(e, "lowAlarm")}/>
                &ensp;{unit}
              </td>
            </tr>
          </tbody>
        </table>
        <input className="saveButton" type="submit" value="Save" />
        </form>
      </div>
      <br/>
      </div>
    )
  }   
}
export default AlarmConfig