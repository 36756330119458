/*******************************************************************************
  DownloadRounds.js

  Author: Mark Duckworth; Advait; 

*******************************************************************************/

import React, { Component } from 'react'
import { API } from 'aws-amplify'
// ability to save a file we download through an API
import FileSaver from 'file-saver'
// ensure a filename does not have invalid characters
import sanitize from 'sanitize-filename'
import { getTaskmanUrl } from '../apihelpers/apihelpers'
import '../index.css'

/**
 * Component to let a user select an disinfection / inspection / security patrol round from a list of rounds,
 * that occurred in the past month. After selecting the round, the user can
 * click a link to download details about that round.
 *
 */
class DownloadRoundData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rounds: [],
      selectedRound: "",
      roundType: this.props.roundType
    };
    this.handleSelectedRound = this.handleSelectedRound.bind(this);
    this.doDownload = this.doDownload.bind(this);
    this.taskmanUrl = getTaskmanUrl(); // beginning part of task manager URL
  }

  componentDidMount() {
    this.fetchRounds();
  }

  componentDidUpdate(prevProps) {
    if (this.props.siteid !== prevProps.siteid) {
      this.setState({ rounds: [], selectedRound: "" });
      this.fetchRounds();
    }
  }

  /**
   * Fetch this list of rounds to choose from, for the chosen site
   */
  fetchRounds() {
    const siteid = this.props.siteid;
    // example http://<taskman>/sites/<siteid>/disinfections?start=2020-02-15T00:00:00&end=2020-03-17T00:00:00
    // times are in the timezone of the site
    if (siteid) {
      const now = new Date();
      let endTime = new Date();
      endTime.setDate(now.getDate() + 2);  // end in future is ok, to make sure the date comes out ahead of 
      // the current date in the site's timezone
      let startTime = new Date(endTime);
      startTime.setDate(endTime.getDate() - 32); // make sure we get at least a full month
      startTime = startTime.toISOString().substring(0, 11) + "00:00:00";
      endTime = endTime.toISOString().substring(0, 11) + "00:00:00";
      API.get('fataskman', `/sites/${siteid}/${this.state.roundType}?start=${startTime}&end=${endTime}`).then(response => {
        this.setState({ rounds: response });
      }).catch(err => {
        console.error("fetchRounds", err);
      });
    }
  }

  handleSelectedRound(event){
      const el = event.target; // the <select> element
      this.setState({selectedRound:el.value,
                     selectedTime:el.options[el.selectedIndex].text,
      });
  }
  
  /**
   * Download a report file via the task manager API, then have the browser
   * save it in the local filesystem.
   */
  doDownload(event){
      const api_path = `/sites/${this.props.siteid}/${this.state.roundType}/${this.state.selectedRound}`;
      const config = {
          responseType: 'blob',
          response: true,
      };

      // Create a filename for the saved file on the local machine
      const  timestamp = this.roundTimeForFilename(this.state.selectedTime);
      let roundType = "unknown";
      switch(this.state.roundType){
        case "inspections":
            roundType = "inspection";
            break;
        case "disinfections":
            roundType = "disinfection";
            break;
        case "patrols":
            roundType = "patrol";
            break;
        default:
            roundType = "unknown_round";
            break;
      }
      const extension = this.state.roundType ==='disinfections' ? 'pdf' : 'csv';
      let filename = `${roundType}_${this.props.sitename}_${timestamp}.${extension}`;
      filename = filename.replace(/ /g, '_');
      filename = sanitize(filename); //remove any illegal filename characters

      console.log(`doDownload() of ${filename} from ${api_path}`);
      API.get('fataskman', api_path, config).then(response => {
          console.log('doDownload() got file response');
          FileSaver.saveAs(response.data, filename);
      }).catch(err => {
        console.error('doDownload()', err);
      });
      event.preventDefault();
  }

    /**
     * Create a timestamp string for a filename. The selected round includes
     * the time in the format yyyy-mm-dd hh:mm:ss.
     * Convert it yyyymmdd_hhmmss.
     */
    roundTimeForFilename(roundTime) {
        let s = roundTime.replace(/-/g, '');
        s = s.replace(' ', '_');
        s = s.replace(/:/g, '');
        return s;
    }

  render() {
    // List of rounds to choose from.
    // Sort to put newest at top of list.
    this.state.rounds.sort((a, b) => a.SK > b.SK ? -1 : 1)
    const options = (this.state.rounds).map((round, i) => {
      return <option key={i} value={round.SK}>
        {round.startTime}
      </option>
    });

    let roundName = "unknown";
    if (this.state.roundType ==="inspections"){
      roundName = "Inspection";
    }else if (this.state.roundType ==="disinfections"){
      roundName = "Disinfection";
    }else if (this.state.roundType ==="patrols"){
      roundName = "Security Patrol";
    }

    let downloadLink = null;
    const buttonText = 'Download '+ roundName + ' Data';
    if (this.state.selectedRound) {
      downloadLink = <input className="saveButton"
                            type="submit"
                            value={buttonText}
                            onClick={this.doDownload}/>
    }

    return (
      <div>
        <span className="individualContainer">
          {(this.state.roundType === 'inspections') ? <h4> Select an {roundName} Round </h4> : <h4> Select a {roundName} Round </h4>}
          <select
            value={this.state.selectedRound}
            onChange={this.handleSelectedRound}
          >
            {(this.state.roundType === 'inspections') ? <option value="">-- Please choose an {roundName} Round --</option> : <option value="">-- Please choose a {roundName} Round --</option>}
            {options}
          </select>
          <br />
          {downloadLink}
        </span>
        <br />
      </div>
    )
  }
}
export default DownloadRoundData