/*******************************************************************************
  ShowRounds.js

  Author: Advait

*******************************************************************************/

import React, { Component } from 'react'

import DownloadRoundData from './DownloadRoundData'
import '../index.css'

class ShowRounds extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedSite: "",
      siteName:"",
    };
  }
  componentDidMount() {
    this.updateSiteInfo();
  }

  componentDidUpdate(prevProps) {
    if (this.props.siteid !== prevProps.siteid) {
      this.setState({ selectedSite:"", siteName:"" });
      this.updateSiteInfo();
    }
    
  }
  updateSiteInfo() {
    this.setState({ selectedSite:this.props.siteid, siteName:this.props.sitename});
  }

  //***************************************************************************
  // render the UI
  //***************************************************************************

  render() {
    if(this.props.hideDisinfectionPage && this.props.hideInspectionPage && this.props.hideSecurityPatrolPage
    ){
      return(
        <div>
            <div className="individualContainer">
                Selected site does not support any round.
                
            </div>
          </div>
      )
    }else{
      return (
        <div>
          {
            this.state.selectedSite && !this.props.hideDisinfectionPage ? 
            <DownloadRoundData 
              roundType="disinfections" 
              siteid={this.state.selectedSite} 
              sitename={this.state.siteName}
            /> : null
          }
          {
            this.state.selectedSite && !this.props.hideInspectionPage ? 
            <DownloadRoundData 
              roundType="inspections" 
              siteid={this.state.selectedSite} 
              sitename={this.state.siteName}
            /> : null
          }      
          {
            this.state.selectedSite && !this.props.hideSecurityPatrolPage ? 
            <DownloadRoundData 
              roundType="patrols" 
              siteid={this.state.selectedSite} 
              sitename={this.state.siteName}
            /> : null
          }   
        </div>
      )
    }
  }
}
export default ShowRounds