/*******************************************************************************
  RobotCurrentMode.js

  Author: Advait

*******************************************************************************/

import React, {Component, Fragment} from 'react'
import {PollSiteStatus} from '../apihelpers/poller'
import {siteDoAction} from '../apihelpers/apihelpers'
import {getBuildingAlarmStatus} from '../apihelpers/apihelpers'
import '../index.css'
import UVLightState from './UVLightState'


var POLL_PERIOD = 20000;

class RobotCurrentMode extends Component{
    constructor(props){
        super(props)
        this.state={
          sites:[],                     // list of sites from database
          selectedSite: this.props.selectedSite,             
          selectedSiteStatus:{},      // status of selected site, from REST API
          modeChangeInProgress: false,  // true right after clicking button to change site mode
          scheduleType: this.props.scheduleType
        }
        this.handleStartStopMode = this.handleStartStopMode.bind(this)
        this.statusPoller = new PollSiteStatus(this);
      }
      
      //***************************************************************************
      // Callback from the status poller.
      // siteResponse - object response from the GET /site/<id> API
      //***************************************************************************
      siteStatusCb(siteResponse){
        // after a mode change, when we get next status we clear the mode change flag
        this.setState({selectedSiteStatus:siteResponse, modeChangeInProgress:false});
      }
    
      async componentDidMount(){
        this.statusPoller.startPoll(0, POLL_PERIOD, this.state.selectedSite);
      }
    
      componentWillUnmount(){
        //clearInterval(this.interval);
        this.statusPoller.stopPoll();
      }
      
    
      //***************************************************************************
      // handle button clicks to start/stop reception or inspection mode
      //***************************************************************************
      async handleStartStopMode(event){
        if (this.state.modeChangeInProgress){
          return;   // don't start another mode change if we just started one
        }
        if(this.state.selectedSite===""){
          alert("Please select a site to start reception mode")
        }else{
          let action = "goto_idle"; // default, for the stop buttons
          if (event.target.id === "startReception"){
            action = "goto_reception";
          } else if (event.target.id === "startInspection"){
            action = "goto_inspection";
          } else if (event.target.id === "startDisinfection"){
            action = "goto_disinfection";
          }else if (event.target.id === "startSecurity Patrol"){
            action = "goto_security_patrol";
          }
          siteDoAction(this.state.selectedSite, action).then(response => {
            console.log("Mode change submitted successfully")
          }).catch(error => {
            alert("error in changing mode: ", error)
          });
          this.setState({modeChangeInProgress:true});
          // Restart poller, giving a few seconds for the robot to change modes
          // before we ask for what the new current mode is.
          this.statusPoller.startPoll(3000, POLL_PERIOD, this.state.selectedSite);
        }
    }
    
      //***************************************************************************
      // Get the current site mode, returning a string suitable for UI display.
      //***************************************************************************
      getSiteMode(getSiteResponse){
        let display_str = "disconnected";
        let mode_str = "unknown"
        try{
          if (getSiteResponse.robotid){
            let connected = getSiteResponse.robotinfo
                     && getSiteResponse.robotinfo.status
                     && getSiteResponse.robotinfo.status.connected;
            let mode_reported = getSiteResponse.shadowstate
                             && getSiteResponse.shadowstate.mode;
            let mode_desired = getSiteResponse.shadowstate_desired
                            && getSiteResponse.shadowstate_desired.mode;
            mode_str = mode_reported;
            // Include the desired mode transition if the desired mode is different from reported mode
            if (mode_desired && mode_desired !== mode_reported){
              mode_str += ' -> ' + mode_desired;
            }
            if (connected){
              display_str = mode_str;
            } else {
              // If the robot is disconnected, there can still be useful mode information
              display_str = `disconnected (${mode_str})`;
            }
          }
        } catch(e) {
          // ignore errors, just use default
        }
        return display_str;
      }

      //***************************************************************************
      // start/stop buttons are enabled only if status says the site has a valid robot,
      // and based on robot health, and other site status.
      //***************************************************************************
      isButtonEnabled(buttonid) {
        let enabled = false;
        let buildingAlarmStatus = getBuildingAlarmStatus(this.state.selectedSiteStatus);
        try{
          let robotHealthy = this.state.selectedSiteStatus.robotinfo.health &&
                             this.state.selectedSiteStatus.robotinfo.health.overallHealth &&
                             (this.state.selectedSiteStatus.robotinfo.health.overallHealth === "OK" ||
                              this.state.selectedSiteStatus.robotinfo.health.overallHealth === "OK_WARN")

          if (this.state.selectedSite
            && !this.state.modeChangeInProgress // keep disabled for a few seconds after click
            && this.state.selectedSiteStatus.robotid
            && this.state.selectedSiteStatus.robotinfo.status.connected
            ){
              let facilitiesState = this.state.selectedSiteStatus.facilitiesState;
              if(buttonid === "startReception" && 
                 this.state.selectedSiteStatus.facilitiesState !== "RECEPTIONIST" && robotHealthy) {
                // Reception has priority, so allow start reception even if the site is in some other mode
                enabled = true;
              } else if (buttonid === "stopReception" && facilitiesState === "RECEPTIONIST") {
                enabled = true;
              } else if (buttonid === "startInspection" && facilitiesState === "SECURITY_PATROL"){
                enabled = true;
              }else if (buttonid.startsWith("start") && facilitiesState === "IDLE" && robotHealthy) {
                if (buttonid === "startDisinfection") {
                  if (buildingAlarmStatus !== "Blocked") enabled = true;
                } else {
                  enabled = true;
                }
              } else if (buttonid === "stopInspection" && facilitiesState === "INSPECTION") {
                enabled = true;
              } else if (buttonid === "stopDisinfection" && facilitiesState === "DISINFECTION") {
                enabled = true;
              }else if (buttonid === "stopSecurity Patrol" && facilitiesState === "SECURITY_PATROL") {
                enabled = true;
              }
          }
        } catch(e) {
          // ignore error, just keep button disabled
        }
        return enabled;
      }

      //***************************************************************************
      // This function will continiously check whether there is change in props 
      // coming from parent. If so, then change the state in current component
      //
      //NOTE: getDerivedStateFromProps method is static so we can not use any value or method
      //      with this associated. Hence we need componentDidUpdate method to perform
      //      further actions.
      //***************************************************************************      
      static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.selectedSite !== prevState.selectedSite){
          return {selectedSite: nextProps.selectedSite }
        }else{
          return null
        }
      }

      //***************************************************************************
      // If the state is changed in above method, then stop polling data for old site
      // and start for new selected site
      //***************************************************************************
      componentDidUpdate(prevProps, prevState) {
        if(this.state.selectedSite !== prevState.selectedSite){
          this.setState({selectedSiteStatus:null})
          this.statusPoller.startPoll(0, POLL_PERIOD, this.state.selectedSite );
        }
      }


      //***************************************************************************
      // render the UI
      //***************************************************************************
      render(){
        let modeName = "unknown";
        if (this.state.scheduleType ==="reception"){
          modeName = "Reception";
        }else if (this.state.scheduleType ==="inspection"){
          modeName = "Inspection";
        }else if (this.state.scheduleType ==="disinfection"){
          modeName = "Disinfection";
        }else if (this.state.scheduleType ==="security_patrol"){
          modeName = "Security Patrol";
        }
        let idStart = "start" + modeName;
        let idStop = "stop" + modeName;

        // Show UV light status only when the robot is in disinfection mode 
        // and robot reports shadowstate response 
        let showUvStatus = false;
        let SiteStatus = this.state.selectedSiteStatus;
        if (SiteStatus && 
          SiteStatus.shadowstate &&
          this.state.scheduleType ==="disinfection"){
          showUvStatus = true;
        }
        if((this.state.scheduleType ==="reception" && this.props.hideReceptionPage) ||
            (this.state.scheduleType ==="inspection" && this.props.hideInspectionPage) ||
            (this.state.scheduleType ==="disinfection" && this.props.hideDisinfectionPage) ||
            (this.state.scheduleType ==="security_patrol" && this.props.hideSecurityPatrolPage)
          ){
            return (
              <div>
                <div className="individualContainer">
                  Selected site does not support {modeName} Mode
                </div>
              </div>
            )
        }else{
          return (
            <div>
              <div className="individualContainer">
                  <h4>Start or End {modeName} Mode</h4>
                  <div style={{'display':'block'}}>
                    <div style={{'display':'block'}}>
                      <span>Site Mode: {this.getSiteMode(this.state.selectedSiteStatus)}</span>
                      {showUvStatus ?
                          <Fragment>
                            <span style={{'float':'right'}}>
                              <span>UV Lights: <UVLightState siteResponse = {this.state.selectedSiteStatus} /> </span>
                            </span> 
                            <br/><span>Building Alarm Status: {getBuildingAlarmStatus(this.state.selectedSiteStatus)}
                            </span>
                          </Fragment> :
                          null
                      }
                    </div>
                  <button id={idStart} className="consoleButton" onClick={this.handleStartStopMode}
                      disabled={!this.isButtonEnabled(idStart)} >
                      Start {modeName} Mode
                  </button>
                  <button id={idStop} className="consoleButton" onClick={this.handleStartStopMode}
                      disabled={!this.isButtonEnabled(idStop)} >
                      End {modeName} Mode
                  </button>
                  </div>
              </div>
              <br/>
            </div>
          )
        }
      }
}
export default RobotCurrentMode