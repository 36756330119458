/*******************************************************************************
  AlarmConfigSection.js

  Author: Mark Duckworth

*******************************************************************************/

import React, { Component } from 'react'
import AlarmConfig from '../components/AlarmConfig'
import { updateSiteThreshold } from '../apihelpers/graphqlhelpers'

//***************************************************************************
// This class handles a group of alarm threshold configurations, for example
// one AlarmConfig for temperature, and one for humidity.
// Initial values to display come from props.
// Parent can change props to update the display with new values.
//***************************************************************************
class AlarmConfigSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      values: props.thresholdValues // object with all threshold values
      // e.g. { temperature: {...}, humidity: {...} }
    }
    this.onSave = this.onSave.bind(this);
  }

  componentDidUpdate(prevProps) {
    // When parent changes values, re-initialize to the values
    // given by the parent in props.
    if (this.props !== prevProps) {
      this.setState({ values: this.props.thresholdValues });
    }
  }

  // called when user clicks Save in a child component
  async onSave(type, thresholdValues) {
    // We have to save the entire json object including all the
    // thresholds.  So start with the original complete json object
    // and update the section the user is saving.
    let newValues = this.state.values;
    newValues[type] = thresholdValues;
    this.setState({ values: newValues });
    console.log("AlarmConfigSection saving", newValues);
    // save the new threshold object to the database
    await updateSiteThreshold(this.props.siteid, newValues);
    this.props.onSaved(); // notify parent we saved new data
  }

  //***************************************************************************
  // render the UI
  //***************************************************************************
  render() {
    return (
      <div>
        <AlarmConfig alarmType='temperature'
          values={this.state.values ? this.state.values.temperature : null}
          onSave={this.onSave} />
        <AlarmConfig alarmType='humidity'
          values={this.state.values ? this.state.values.humidity : null}
          onSave={this.onSave} />
      </div>
    )
  }
}
export default AlarmConfigSection