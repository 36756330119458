/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSite = /* GraphQL */ `
  mutation CreateSite($input: CreateSiteInput!) {
    createSite(input: $input) {
      id
      tid
      siteName
      rmsName
      rmsUser
      rmsPw
      mapName
      emailsToNotify
      adminEmailsToNotify
      alarmThresholds
      ScheduleItems {
        items {
          id
          siteId
          enabled
          type
          day
          startTime
          duration
          tid
          createdAt
          updatedAt
        }
        nextToken
      }
      uvRestrictHours
      buildingAlarmIntegrated
      buildingAlarmState
      timezone
      recharge_threshold_min
      recharge_threshold_max
      restrict_ui_page
      disabled
      securityRound_action
      securityRound_wait_time
      createdAt
      updatedAt
    }
  }
`;
export const updateSite = /* GraphQL */ `
  mutation UpdateSite($input: UpdateSiteInput!) {
    updateSite(input: $input) {
      id
      tid
      siteName
      rmsName
      rmsUser
      rmsPw
      mapName
      emailsToNotify
      adminEmailsToNotify
      alarmThresholds
      ScheduleItems {
        items {
          id
          siteId
          enabled
          type
          day
          startTime
          duration
          tid
          createdAt
          updatedAt
        }
        nextToken
      }
      uvRestrictHours
      buildingAlarmIntegrated
      buildingAlarmState
      timezone
      recharge_threshold_min
      recharge_threshold_max
      restrict_ui_page
      disabled
      securityRound_action
      securityRound_wait_time
      createdAt
      updatedAt
    }
  }
`;
export const deleteSite = /* GraphQL */ `
  mutation DeleteSite($input: DeleteSiteInput!) {
    deleteSite(input: $input) {
      id
      tid
      siteName
      rmsName
      rmsUser
      rmsPw
      mapName
      emailsToNotify
      adminEmailsToNotify
      alarmThresholds
      ScheduleItems {
        items {
          id
          siteId
          enabled
          type
          day
          startTime
          duration
          tid
          createdAt
          updatedAt
        }
        nextToken
      }
      uvRestrictHours
      buildingAlarmIntegrated
      buildingAlarmState
      timezone
      recharge_threshold_min
      recharge_threshold_max
      restrict_ui_page
      disabled
      securityRound_action
      securityRound_wait_time
      createdAt
      updatedAt
    }
  }
`;
export const createTenant = /* GraphQL */ `
  mutation CreateTenant($input: CreateTenantInput!) {
    createTenant(input: $input) {
      id
      company
      sites {
        items {
          id
          tid
          siteName
          rmsName
          rmsUser
          rmsPw
          mapName
          emailsToNotify
          adminEmailsToNotify
          alarmThresholds
          uvRestrictHours
          buildingAlarmIntegrated
          buildingAlarmState
          timezone
          recharge_threshold_min
          recharge_threshold_max
          restrict_ui_page
          disabled
          securityRound_action
          securityRound_wait_time
          createdAt
          updatedAt
        }
        nextToken
      }
      disabled
      createdAt
      updatedAt
    }
  }
`;
export const updateTenant = /* GraphQL */ `
  mutation UpdateTenant($input: UpdateTenantInput!) {
    updateTenant(input: $input) {
      id
      company
      sites {
        items {
          id
          tid
          siteName
          rmsName
          rmsUser
          rmsPw
          mapName
          emailsToNotify
          adminEmailsToNotify
          alarmThresholds
          uvRestrictHours
          buildingAlarmIntegrated
          buildingAlarmState
          timezone
          recharge_threshold_min
          recharge_threshold_max
          restrict_ui_page
          disabled
          securityRound_action
          securityRound_wait_time
          createdAt
          updatedAt
        }
        nextToken
      }
      disabled
      createdAt
      updatedAt
    }
  }
`;
export const deleteTenant = /* GraphQL */ `
  mutation DeleteTenant($input: DeleteTenantInput!) {
    deleteTenant(input: $input) {
      id
      company
      sites {
        items {
          id
          tid
          siteName
          rmsName
          rmsUser
          rmsPw
          mapName
          emailsToNotify
          adminEmailsToNotify
          alarmThresholds
          uvRestrictHours
          buildingAlarmIntegrated
          buildingAlarmState
          timezone
          recharge_threshold_min
          recharge_threshold_max
          restrict_ui_page
          disabled
          securityRound_action
          securityRound_wait_time
          createdAt
          updatedAt
        }
        nextToken
      }
      disabled
      createdAt
      updatedAt
    }
  }
`;
export const createScheduleItem = /* GraphQL */ `
  mutation CreateScheduleItem($input: CreateScheduleItemInput!) {
    createScheduleItem(input: $input) {
      id
      siteId
      enabled
      type
      day
      startTime
      duration
      tid
      createdAt
      updatedAt
    }
  }
`;
export const updateScheduleItem = /* GraphQL */ `
  mutation UpdateScheduleItem($input: UpdateScheduleItemInput!) {
    updateScheduleItem(input: $input) {
      id
      siteId
      enabled
      type
      day
      startTime
      duration
      tid
      createdAt
      updatedAt
    }
  }
`;
export const deleteScheduleItem = /* GraphQL */ `
  mutation DeleteScheduleItem($input: DeleteScheduleItemInput!) {
    deleteScheduleItem(input: $input) {
      id
      siteId
      enabled
      type
      day
      startTime
      duration
      tid
      createdAt
      updatedAt
    }
  }
`;
