/*******************************************************************************
  DevPage.js

  Author: Advait

  This file should be used for developement and testing code only
*******************************************************************************/

import React, { Component } from 'react'
import { API, graphqlOperation } from 'aws-amplify';
import {createScheduleItem } from '../graphql/mutations'
import { getTenantsWithSites } from '../apihelpers/graphqlhelpers'
import '../index.css'

const listSchedules = `query ListScheduleItems(
  $filter: ModelScheduleItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listScheduleItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      siteId
      enabled
      type
      day
      startTime
      duration
    }
    nextToken
  }
}
`;

class DevPage extends Component {
  constructor(props) {
    super(props);
    this.handleTenantChange = this.handleTenantChange.bind(this);
    this.handleSelectedSite = this.handleSelectedSite.bind(this)

    this.state = {
      siteid: "",
      refreshCount: 0,  // increment to cause the SelectSiteSimple to refresh from database
      company: "",
      tenants: [],
      sites: [],
      value: "",
      selectedTenant: "",
      selectedSite: "",
      createSchedule:false
    }
    this.createSecurityPatrolSchedules = this.createSecurityPatrolSchedules.bind(this)
  }


  async componentDidMount() {
    let tenants = await getTenantsWithSites(false);
    this.setState({ tenants: tenants });
    this.updateSchedules();
  }
  

  componentDidUpdate(prevProps, prevState) {
    if(this.state.selectedSite !== prevState.selectedSite){
      this.updateSchedules()
    }
  }

  handleSelectedSite(event) {
    const siteid = event.target.value;
    this.setState({ selectedSite: siteid })
    //this.onChange(siteid, this.getSiteInfo(siteid));
  }

  //***************************************************************************
  // Called when user has saved new values in the Site table.
  // Ask the SelectSiteSimple component to refresh its values from
  // the database so we don't get any stale data when user switches sites.
  //***************************************************************************
  handleThresholdsSaved() {
    this.setState({ refreshCount: this.state.refreshCount + 1 });
  }

  /**
   * called when the user selects a different tenant, or no site.
   * @param {object} siteinfo has some Site fields from the database
   */
  handleTenantChange(event) {
    const tenantid = event.target.value;
    this.setState({ selectedTenant: tenantid })

    let tenants = this.state.tenants
    let sites = []
    for (const tenant of tenants) {
      if (tenant.id === tenantid) {
        sites = tenant.sites.items
      }
    }

    this.setState({ sites: sites })
  }

 /**
   * queries the Schedule dynamodb table and then handles the state change
   */
  async updateSchedules(){
    // get the schedule items from grphql query
    // filter based on type and siteId
    let userRequestedItemType = "security_patrol"
    let userRequestedSite  = this.state.selectedSite
    let siteAndTypeFilter= {
      siteId: {
        eq: userRequestedSite
      },
      type: {
        eq: userRequestedItemType
      }
    }
    let  list_schedules = await API.graphql(graphqlOperation(listSchedules,
      {
        filter: siteAndTypeFilter,
        limit: 500
      }))
    let allschedules = list_schedules.data.listScheduleItems.items
    //***************************************************************************
    // If the token is null that means there is no more data in the database,
    // but if the there is token then following code will run until we recieve the
    // null token.
    //***************************************************************************
    let token = list_schedules.data.listScheduleItems.nextToken
    while(token !==null){
      let newSchedules={}
      newSchedules = await API.graphql(graphqlOperation(listSchedules, 
        {
          filter: siteAndTypeFilter,
          limit: 500,
          nextToken: token
        }))
      token = newSchedules.data.listScheduleItems.nextToken;
      allschedules = allschedules.concat(newSchedules.data.listScheduleItems.items)
    }

    // If the scheduleType is security_patrol and there are no schedule items then create 
    // 7 schedule items for security_patrol.
    if (allschedules.length === 0){
      
      this.setState({ createSchedule: true })
    }else{
      this.setState({ createSchedule: false })
    }
  }





  //***************************************************************************
  // If the selected site does not have security patrol schedules, create new 
  // schedule and update the UI and display the content as per latest states.
  //***************************************************************************
  async createSecurityPatrolSchedules() {
    console.log("creating security patrol schedules for site with siteID: ",this.state.selectedSite)
     var i = 0;
    do{
      let ScheduleItem = {
        siteId: this.state.selectedSite,
        enabled: false,
        day: (i % 7).toString(),
        tid: this.state.selectedTenant,
        startTime: "00:30",
        type: "security_patrol",
        duration: "4:00"
      }
      console.log("ScheduleItem: ", ScheduleItem)
      try{
        const response =  await API.graphql(graphqlOperation(createScheduleItem, {input:ScheduleItem}))
        console.log("response", response)
        if(response!==''){
          console.log("schedule created successfully.")
        }
      } catch(e){
        console.log(e)
      }
      i++
    } while (i < 7)
    this.updateSchedules()
  }

  //***************************************************************************
  // render the UI
  //***************************************************************************
  render() {
    const allTenants = [].concat(this.state.tenants)
      .map((tenant, i) => {
        return <option key={i} value={tenant.id}>
          {tenant.company}
        </option>
      }
      )
    // Sites for selected Tenant
    const getTenantSites = [].concat(this.state.sites)
      .map((site, i) => {
        return <option key={i} value={site.id}>
          {site.siteName}
        </option>
      }
      )

    return (
      <div className="fullPageDiv">
        <div className="individualContainer">
          <h4>Tenant Selection</h4>
          <br />
          <select
            value={this.state.selectedTenant}
            onChange={this.handleTenantChange}
          >
            <option value="">-- Please choose a Tenant --</option>
            {allTenants}
          </select>
        </div>
        <br />
        {
          this.state.selectedTenant ?
            <div className="individualContainer">
              <h4>Site Selection</h4>
              <br />
              <select
                value={this.state.selectedSite}
                onChange={this.handleSelectedSite}
              >
                <option value="">-- Please choose a site --</option>
                {getTenantSites}
              </select>
            </div>
            : null
        }
        <br/>
         {
          this.state.selectedTenant && this.state.selectedSite ?
            <div className="individualContainer">
              {
                this.state.createSchedule ? 
                <button className="startCreateButton" onClick={this.createSecurityPatrolSchedules} style={{'width': 'fitContent !important'}} >Create Securty Patrol Schedule</button> :
                <h4> Security Patrol Schedule Items are already present </h4>
              }
              
            </div>
            : null
        }

      </div>
    )
  }
}
export default DevPage
