import {API} from 'aws-amplify'

  //***************************************************************************
  // Poller base class to implement periodic polling.
  //***************************************************************************
  class Poller {
    constructor(cbObj){
      this.cbObj = cbObj;   // object with callback method to receive results of the poll
      this.timer = null;
      this.interval = null;
      this.startPeriodic = this.startPeriodic.bind(this);
      this.doPoll = this.doPoll.bind(this);
    }
  
    doPoll(){
        //this should be overridden in derived class
    }

    //***************************************************************************
    // startPeriodic()
    //   invoke the first poll; start the subsequent periodic polls
    //***************************************************************************
    startPeriodic(){
      this.doPoll();
      this.interval = setInterval(this.doPoll, this.period);
    }
  
    //***************************************************************************
    // startPoll()
    //   start (or restart) polling
    // initialDelay - time in ms to wait before the first poll
    // period - time in ms between subsequent polls
    //***************************************************************************
    startPoll(initialDelay, period){
      this.period = period;
      this.stopPoll();
      this.timer = setTimeout(this.startPeriodic, initialDelay);
    }
  
    //***************************************************************************
    // stopPoll()
    //***************************************************************************
    stopPoll(){
      clearTimeout(this.timer);
      clearInterval(this.interval);
      this.timer = null;
      this.interval = null;
    }
  }

  //***************************************************************************
  // Derived class for polling facilities site status.
  // The cbObj parameter must be an object with a siteStatusCb() method.
  //***************************************************************************
  class PollSiteStatus extends Poller {
    constructor(cbObj){
      super(cbObj);
      this.site = null;  // site id
    }
  
    getSiteStatus(){
      if (this.site) {
        API.get('fataskman', '/sites/'+ this.site).then(response => {
          this.cbObj.siteStatusCb(response);
        }).catch(err => {
          this.cbObj.siteStatusCb(null);
        });
      }
    }
  
    doPoll(){
        this.getSiteStatus();
    }

    startPoll(initialDelay, period, site){
        this.site = site;
        super.startPoll(initialDelay, period);
    }
  }

  export {PollSiteStatus}