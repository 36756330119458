//
import React, {Component} from 'react';
import customAuthenticator from './components/CustomAuth';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports'; // if you are using Amplify CLI
import Routes from './views/Routes'
import {getTaskmanUrl} from './apihelpers/apihelpers'

Amplify.configure(awsconfig);

// Configure the base URL for the fataskman service.
// This value comes from Amazon API Gateway, which links this URL to 
// the actual service running in a Docker container.
var taskmanUrl = getTaskmanUrl();
console.log("taskmanUrl: " + taskmanUrl);

Amplify.configure({
  API: {
    // This part sets up using the IdToken instead of AccessToken for authorization.
    // This is so the backend can verify the tenant id value in the IdToken.
    graphql_headers: async () => {
      const session = await Amplify.Auth.currentSession();
      return ({
        'Authorization': session.getIdToken().getJwtToken()
      })},
    endpoints: [
        {
            name: "fataskman",
            endpoint: taskmanUrl,
            custom_header: 
              async () => {
                const session = await Amplify.Auth.currentSession();
                return ({
                  Authorization : session.getIdToken().getJwtToken()
                })
              }
        }
    ]
  }
});

class App extends Component {
  constructor(props){
    super(props)
    this.state={
        sysadmin:false,
    };
  }

  async componentDidMount(){
    //this is called once the page is load
    //it wont be called again if something changes on the page
    const session = await Amplify.Auth.currentSession();
    if (session.getIdToken().payload["cognito:groups"] === undefined){
      console.log("User does not belong to any groups")
      localStorage.setItem('sysadmin', false);
    } else if (session.getIdToken().payload["cognito:groups"][0] === "sysadmin"){
      console.log("User belong to sysadmin group")
      this.setState({sysadmin:true})
      localStorage.setItem('sysadmin', true);
    }
    //set local data base
    localStorage.setItem('selectedTenant', "");
    localStorage.setItem('selectedSite', "");
    localStorage.setItem('emailsToNotify', "");
    localStorage.setItem('alarmThresholds', "");
    localStorage.setItem('adminEmailsToNotify', "");
  }
  async componentWillUnmount(){
    //clear local data base
    localStorage.setItem('sysadmin', "");
    localStorage.setItem('selectedTenant', "");
    localStorage.setItem('selectedSite', "");
    localStorage.setItem('emailsToNotify', "");
    localStorage.setItem('alarmThresholds', "");
    localStorage.setItem('adminEmailsToNotify', "");
  }
  render() {
    return (
        <div className="App" style= {{'overflow':'hidden'}}>
          <Routes user={{sysadmin:this.state.sysadmin}}/>
        </div>
    );
  }
}

export default customAuthenticator(App);
