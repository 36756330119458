/*******************************************************************************
  ScheduleItemTable.js

  Author: Advait

*******************************************************************************/
import React, { Component } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { updateScheduleItem } from '../graphql/mutations'
//import {  } from '../graphql/mutations'
import '../index.css'


const hours = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
const hour = hours.map((hours) =>
  <option key={hours} value={hours}>{hours}</option>
);

const duration_hours = ['0', '1', '2', '3', '4', '5', '6', '7', '8'];
const duration = duration_hours.map((duration_hours) =>
  <option key={duration_hours} value={duration_hours}>{duration_hours}</option>
);

//Restricted Hours for Disinfection round
const restricted_hours = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
const restricted_hour = restricted_hours.map((restricted_hours) =>
  <option key={restricted_hours} value={restricted_hours}>{restricted_hours}</option>
);

const minutes = ['00', '15', '30', '45'];
const minute = minutes.map((minutes) =>
  <option key={minutes} value={minutes}>{minutes}</option>
);

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const graphqlLimit = 500

const listSchedules = `query ListScheduleItems(
  $filter: ModelScheduleItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listScheduleItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      siteId
      enabled
      type
      day
      startTime
      duration
    }
    nextToken
  }
}
`;


class ScheduleItemTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedSite: this.props.selectedSite, // id of site the user selected
      reception: false,     // true to show duration fields
      siteSchedule: [],     // list of all schedules for a site    
      scheduleType: this.props.scheduleType,  // e.g. 'reception', 'inspection', 'disinfection'
      listAllSchedule: []  // to store all the schedules belonging to the tenant
    }
    this.handleUpdateScheduleValues = this.handleUpdateScheduleValues.bind(this)
    this.handleUpdateSchedule = this.handleUpdateSchedule.bind(this)
    this.handleChangeState = this.handleChangeState.bind(this)
  }

  /**
   * queries the Schedule dynamodb table and then handles the state change
   */
  async updateSchedules(){
    // get the schedule items from grphql query
    // filter based on type and siteId
    let userRequestedItemType = this.state.scheduleType
    let userRequestedSite  = this.state.selectedSite
    let siteAndTypeFilter= {
      siteId: {
        eq: userRequestedSite
      },
      type: {
        eq: userRequestedItemType
      }
    }
    let  list_schedules = await API.graphql(graphqlOperation(listSchedules,
      {
        filter: siteAndTypeFilter,
        limit: graphqlLimit
      }))
    let allschedules = list_schedules.data.listScheduleItems.items
    //***************************************************************************
    // If the token is null that means there is no more data in the database,
    // but if the there is token then following code will run until we recieve the
    // null token.
    //***************************************************************************
    let token = list_schedules.data.listScheduleItems.nextToken
    while(token !==null){
      let newSchedules={}
      newSchedules = await API.graphql(graphqlOperation(listSchedules, 
        {
          filter: siteAndTypeFilter,
          limit: graphqlLimit,
          nextToken: token
        }))
      token = newSchedules.data.listScheduleItems.nextToken;
      allschedules = allschedules.concat(newSchedules.data.listScheduleItems.items)
    }

    this.setState({ listAllSchedule: allschedules })
    this.handleChangeState()
  }


  async componentDidMount(){
    this.updateSchedules()
  }


  //***************************************************************************
  // If the states are changed based on componentDidUpdate method, update
  // the UI and display the content as per latest states.
  //***************************************************************************
  handleChangeState() {
    let allschedules = this.state.listAllSchedule
    //***************************************************************************
    // Convert the 24 hr time format string values into 12 hr time format
    //***************************************************************************
    for (let i in allschedules) {
      let day = allschedules[i].day

      let startTime = allschedules[i].startTime.split(":")
      // All numeric values are stored as strings in allschedules items.
      // Let javascript auto convert string to number for numeric operations.
      allschedules[i].startMin = startTime[1]
      if (startTime[0] === "00") {
        allschedules[i].startHr = "12"
        allschedules[i].startAM = "AM"
      } else if (startTime[0] < 12) {
        allschedules[i].startHr = startTime[0]
        allschedules[i].startAM = "AM"
      } else if (startTime[0] === "12") {
        allschedules[i].startHr = "12"
        allschedules[i].startAM = "PM"
      } else {
        allschedules[i].startHr = (startTime[0] - 12).toString()
        allschedules[i].startAM = "PM"
      }
      if (allschedules[i].duration) {
        let durationTime = allschedules[i].duration.split(":")
        allschedules[i].durationHr = durationTime[0];
        allschedules[i].durationMin = durationTime[1]
      }
      allschedules[i].dayName = days[day]
    }
  
    //sort based on day number
    allschedules.sort(function (a, b) {
      return a.day.localeCompare(b.day);
    });


    this.setState({ siteSchedule: allschedules });

    //***************************************************************************
    // Maintain a state called reception to decide whether or mot to show the 
    // duration fields in schedule table.
    //***************************************************************************

    if (this.state.scheduleType === "reception") {
      this.setState({ reception: true })
    }
  }

  //***************************************************************************
  // update the schedule item json if user changes any value from the table
  //***************************************************************************
  handleUpdateScheduleValues(e, i, variable, siteSchedule) {

    let currentSchedule = this.state.siteSchedule;
    let UpdatedSchedule = siteSchedule

    // Update parameters which are changed by user
    if (variable === "startHr") {
      UpdatedSchedule.startHr = e.target.value
    } else if (variable === "startMin") {
      UpdatedSchedule.startMin = e.target.value
    } else if (variable === "startAM") {
      UpdatedSchedule.startAM = e.target.value
    } else if (variable === "durationHr") {
      UpdatedSchedule.durationHr = e.target.value
    } else if (variable === "durationMin") {
      UpdatedSchedule.durationMin = e.target.value
    } else if (variable === "enabled") {
      UpdatedSchedule.enabled = !UpdatedSchedule.enabled
    }

    //convert the updated time into 24 hr string format
    //NOTE: If need to change the time format as per the user location then chnage the code here
    if (UpdatedSchedule.startAM === "AM") {
      if (UpdatedSchedule.startHr === "12") {
        UpdatedSchedule.startTime = "00:" + UpdatedSchedule.startMin
      } else {
        UpdatedSchedule.startTime = UpdatedSchedule.startHr + ":" + UpdatedSchedule.startMin
      }
    } else {
      if (UpdatedSchedule.startHr === "12") {
        UpdatedSchedule.startTime = "12:" + UpdatedSchedule.startMin
      } else {
        UpdatedSchedule.startTime = parseInt(UpdatedSchedule.startHr) + 12 + ":" + UpdatedSchedule.startMin
      }
    }

    let duration = UpdatedSchedule.durationHr + ":" + UpdatedSchedule.durationMin

    UpdatedSchedule.duration = duration

    currentSchedule[i] = UpdatedSchedule
    this.setState({ siteSchedule: currentSchedule })
  }

  //***************************************************************************
  // Call update mutation on save button
  //***************************************************************************
  async handleUpdateSchedule(e) {
    const UpdatedSchedule = this.state.siteSchedule
    console.log("UpdatedSchedule", UpdatedSchedule)
    let schedule = {}
    for (let i in UpdatedSchedule) {
      schedule = {
        id: UpdatedSchedule[i].id,
        enabled: UpdatedSchedule[i].enabled,
        startTime: UpdatedSchedule[i].startTime,
        duration: UpdatedSchedule[i].duration,
      }
      console.log("schedule", schedule)
      try {
        const response = await API.graphql(graphqlOperation(updateScheduleItem, { input: schedule }))
        console.log(response)
        if (response !== '') {
          console.log("Schedule updated successfully.")
        }
      } catch (e) {
        console.log(e)
      }
    }
    try {
      // tell task manager we just updated the schedule
      await API.post('fataskman', '/sites/' + this.state.selectedSite, { body: { action: "update_schedule" } });
    } catch (e) {
      console.log(e)
    }
  }

  //***************************************************************************
  // This function will continiously check whether there is change in props 
  // coming from parent. If so, then change the state in current component
  //
  //NOTE: getDerivedStateFromProps method is static so we can not use any value or method
  //      with this associated. Hence we need componentDidUpdate method to perform
  //      further actions.
  //***************************************************************************      
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.selectedSite !== prevState.selectedSite) {
      return { selectedSite: nextProps.selectedSite, }
    } else {
      return null
    }
  }

  //***************************************************************************
  // If the state is changed in above method, then stop polling data for old site
  // and start for new selected site
  //***************************************************************************
  componentDidUpdate(prevProps, prevState) {
    if(this.state.selectedSite !== prevState.selectedSite){
      this.updateSchedules()
    }
  }

  //***************************************************************************
  // render the UI
  //***************************************************************************

  render() {
    const scheduleData = [].concat(this.state.siteSchedule)
      .map((siteSchedule, i) =>
        <tr key={i}>
          <td>{siteSchedule.dayName}</td>
          <td>
            <input
              onChange={(e) => this.handleUpdateScheduleValues(e, i, "enabled", siteSchedule)}
              name="completed"
              type="checkbox"
              checked={siteSchedule.enabled}
            />
          </td>
          <td>
            <select value={siteSchedule.startHr} onChange={(e) => this.handleUpdateScheduleValues(e, i, "startHr", siteSchedule)}>
              {hour}
            </select>

            <select value={siteSchedule.startMin} onChange={(e) => this.handleUpdateScheduleValues(e, i, "startMin", siteSchedule)}>
              {minute}
            </select>

            <select value={siteSchedule.startAM} onChange={(e) => this.handleUpdateScheduleValues(e, i, "startAM", siteSchedule)}>
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </select>
          </td>
          {((this.state.reception) || (this.state.scheduleType === "disinfection_allowed")|| ((this.state.scheduleType === "security_patrol"))) ?
            <td>
              {(this.state.scheduleType === "disinfection_allowed")? 
                <select value={siteSchedule.durationHr} onChange={(e) => this.handleUpdateScheduleValues(e, i, "durationHr", siteSchedule)}>
                  {restricted_hour}
                </select> :
                <select value={siteSchedule.durationHr} onChange={(e) => this.handleUpdateScheduleValues(e, i, "durationHr", siteSchedule)}>
                  {duration}
                </select>
              }
              <select value={siteSchedule.durationMin} onChange={(e) => this.handleUpdateScheduleValues(e, i, "durationMin", siteSchedule)}>
                {minute}
              </select>
            </td> 
          : null}
        </tr>
      )
    return (
      <div>
        <div className="individualContainer">
        {(this.state.scheduleType === "disinfection_allowed") ? null : <h4>Schedule Items</h4>}
          <table>
            <thead>
              <tr>
                <th>Day</th>
                {(this.state.scheduleType === "disinfection_allowed") ? <th>Allowed</th> : <th>Enabled</th>}
                <th>Start Time</th>
                {((this.state.reception) || 
                  (this.state.scheduleType === "disinfection_allowed")) ||
                  (this.state.scheduleType === "security_patrol")? <th>Duration</th> : null}
              </tr>
            </thead>
            <tbody>
              {scheduleData}
            </tbody>
          </table>
          <br />
          <button onClick={this.handleUpdateSchedule}>Save</button>
        </div>
        <br/>
      </div>
    )
  }
}
export default ScheduleItemTable