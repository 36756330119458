/*******************************************************************************
  ReceptionistConfig.js

  Author: Advait

*******************************************************************************/
import React, {Component} from 'react'
import SelectSite from '../components/SelectSite'
import '../index.css'

class ReceptionistConfig extends Component{

  //***************************************************************************
  // render the UI
  //***************************************************************************
  render(){
    return (
      <div className="fullPageDiv">
        <h3>Reception Mode Configuration</h3>
        <SelectSite pageType ="roundConfig" scheduleType ="reception"/>
      </div>
    )
  }
}
export default ReceptionistConfig
