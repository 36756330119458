/*
 * Components for customizing the authentication flow,
 * by customizing certain aspects of the withAuthenticator
 * "higher order component" from aws-amplify-react.
 */
import React from 'react';
import {withAuthenticator, SignIn, Greetings, ForgotPassword, RequireNewPassword, ConfirmSignIn, VerifyContact} from 'aws-amplify-react';
import {I18n} from 'aws-amplify';

import '../index.css'
import logo from "../assets/img/logo-ava.png";

// This affects "Reset your password" and other pages from aws-amplify-react,
// when using the UI auth components from the library.
const customTheme = {
    sectionHeader: { 'color': 'white' }, // form header 'Reset your password'
    inputLabel: { 'color': 'white' }, // labels for text input boxes
    formSection: { 'backgroundColor': '#3e444a' },
    container: { 'backgroundColor': '#3e444a' },
    // navButton affects the "sign out" button in the Greetings component.
    navButton: { 'backgroundColor': '#55c521',
                 'textTransform': 'none',
                 'fontSize': '1rem',
                 'padding': '12px',
                 'letterSpacing': 'normal'
               },
    // button affects the "send code" button in the library's ForgotPassword component.
    // This might not be needed since we switched to a custom component.
    button: { 'backgroundColor': '#55c521',
              'textTransform': 'none',
              'fontSize': '1rem',
              'padding': '12px',
              'letterSpacing': 'normal'
            },
};

// List of usernames which need to be modified in order to make input case insensitive.
const usernamesToUpdate = {
  'aashay.bhatt@fmr.com':'Aashay.Bhatt@fmr.com' ,
  'jonathan.green@fmr.com':'Jonathan.Green@fmr.com'
}

  /**
   * Make input for case insensitivity by triming whitespaces and converting into lowercase
   * check if input is special case, if yes then return respective value
  **/
function makeUsernameCaseInsensitive(input){
    var username = input.trim();
    username = username.toLowerCase();
    if (usernamesToUpdate.hasOwnProperty(username)){
      username = usernamesToUpdate[username];
    }
    return username
}
/*
 * Override the display of the SignIn page
 */
class CustomSignIn extends SignIn {
  constructor(props) {
    super(props)
    this._validAuthStates = ['signIn', 'signedOut', 'signedUp']
  }

  // check input for case insensitivity
  getUsernameFromInput() {
    return makeUsernameCaseInsensitive(super.getUsernameFromInput());
  }

  showComponent(theme) {
    return (
      <div className="authWindow" >
        <div className="loginbox">
            <div className="head" style={{textAlign:"center"}}>
                <img src={logo} alt="logo_image" />
            </div >
            <div className="body">
                <p>Please enter your credentials to access the Ava<sup>&#174;</sup> Facilities Manager Hub</p>
                <br/>
                <form className="form-login" autoComplete="off" onSubmit={this.signIn}>
                    <div className="form-group">
                        <input
                          id="username"
                          className="form-control"
                          key="username"
                          name="username"
                          onChange={this.handleInputChange}
                          type="text"
                          placeholder="Username"
                        />
                    </div>
                    <div className="form-group">
                        <input
                          id="password"
                          className="form-control"
                          key="password"
                          name="password"
                          onChange={this.handleInputChange}
                          type="password"
                          placeholder="Password"
                        />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary btn-block"
                    >
                      Log In
                    </button>
                    <br/>
                    <p>
                      Forgot your password?{' '}
                      <a
                        style={{cursor:"pointer"}}
                        onClick={() => super.changeState('forgotPassword')}
                      >
                        Reset Password
                      </a>
                    </p>
                </form>
            </div>
        </div>
        <div>
          <span style={{position:"fixed", bottom:"15px", width:"100%", textAlign:"center"}}>
            By using Ava Facilities Manager Hub you accept the 
            <a href="https://www.avarobotics.com/terms-conditions" target="_blank"> Terms of Service</a>,
            <a href="https://www.avarobotics.com/privacy" target="_blank"> Privacy Statement</a>,
            Notices & Disclaimers.
          </span>
        </div>
      </div>
    )
  }
}

/*
 * Override the display of pages to reset a forgotten password.
 * For reference, see the library source code in
 * aws-amplify-react/src/Auth/ForgotPassword.tsx
 */
class CustomForgot extends ForgotPassword {

  // check input for case insensitivity
  getUsernameFromInput() {
    return makeUsernameCaseInsensitive(super.getUsernameFromInput());
  }

  send(event) {
    if (event) event.preventDefault();
    super.send();
  }
  
  submit(event) {
    if (event) event.preventDefault();
    // Tolerate extra whitespace around the reset code
    this.inputs['code'] = this.inputs['code'].trim();
    super.submit();
  }

  /**
   * Form to enter a username and request a reset code
   * 
   */
  requestCodeView() {
    // Not sure if this pre-filled username is ever used, but the superclass has it
    // so we included it here too.
    let usernameValue;
    if (window && window.location && window.location.search) {
        const searchParams = new URLSearchParams(window.location.search);
        usernameValue = searchParams ? searchParams.get('username') : undefined
    }
    return(
      <form className="form-login" autoComplete="off" onSubmit={this.send}>
          <div className="form-group">
                <p>Please enter your username (this may be your email address)</p>
                <br/>
                <input
                  value={usernameValue}
                  autofocus
                  id="username"
                  className="form-control"
                  key="username"
                  name="username"
                  onChange={this.handleInputChange}
                  type="text"
                  placeholder={I18n.get('Enter your username')}
                />
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-block"
          >
            {I18n.get('Send Code')}
          </button>
        <br/>
        <p>
          <a style={{cursor:"pointer"}} onClick={() => this.changeState('signIn')} >
              {I18n.get('Back to Sign In')}
          </a>
        </p>
      </form>
    );
  }

  /**
   * Form to enter a reset code and a new password, to reset a forgotten password
   * 
   */
  submitCodeView() {
    return(
      <form className="form-login" autoComplete="off" onSubmit={this.submit}>
          <p>Do not close this browser window. Go to your email and look for a message with your reset code.</p>
          <p>Enter the reset code and create a new password below</p>
          <br/>
          <div className="form-group">
                <p style={{color:"white"}}>Enter the reset code</p>
                <input
                  autofocus
                  id="code"
                  className="form-control"
                  key="code"
                  name="code"
                  onChange={this.handleInputChange}
                  type="text"
                  placeholder={I18n.get('Code')}
                  autoComplete="off"
                />
          </div>
          <br/>
          <div className="form-group">
                <p style={{color:"white"}}>Create new password</p>
                <input
                  id="password"
                  className="form-control"
                  key="password"
                  name="password"
                  onChange={this.handleInputChange}
                  type="password"
                  placeholder={I18n.get('New password')}
                  autoComplete="off"
                />
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-block"
          >
            {I18n.get('Submit')}
          </button>
        <br/>
        <p>
          <a style={{cursor:"pointer"}} onClick={this.send} >
              {I18n.get('Resend Code')}
          </a>
        </p>
      </form>
    );
  }

  /**
   * Show the different pages of the "reset forgotten password" flow,
   * depending on the component's state.
   * 
   */
  showComponent(theme) {
    const { authData={} } = this.props;

    return(
      <div className="authWindow" >
        <div className="loginbox">
            <div className="body">
                <p style={{color:"white"}}>Reset your password</p>
                <br/>
                { this.state.delivery || authData.username ? this.submitCodeView() : this.requestCodeView() }
            </div>
        </div>
      </div>
    );
  }
}

// List of components used by withAuthenticator. We can include our own custom components
// and components that come straight from the aws-amplify-react library
const authComponents = [
    <CustomSignIn/>,
    <Greetings/>, // greeting and sign out button on top of all pages
    <CustomForgot/>, // page for help when you forgot your password
    <RequireNewPassword />, // page for user to enter a new password after getting temporary password
    <ConfirmSignIn/>,
    <VerifyContact/>,
];

function customAuthenticator (app) {
    return withAuthenticator(app, true, authComponents, null, customTheme);
}

export default customAuthenticator