/*******************************************************************************
  Routes.js

  Author: Advait

  This file is responsible for router implementation
*******************************************************************************/
import React from 'react'
import { BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import Home from './Home'
import SystemConfig from './SystemConfig'
import SiteInspectionConfig from './SiteInspectionConfig'
import DisinfectionConfig from './DisinfectionConfig'
import ReceptionistConfig from './ReceptionistConfig'
import SecurityConfig from './SecurityConfig'
import Reporting from './Reporting'
import CreateSite from './CreateSite'
import CreateTenant from './CreateTenant'
import DevPage from './DevPage'
import logo from "../assets/img/logo-ava.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css'

function Routes(props){
    return (
      <Router>
      <div className="sidebar">
        <div className="logo-img" >
         <a href="/"><img src={logo} alt="logo_image" /></a>
        </div >
        <div style={{width:'100%', padding:'10%'}}>
          <NavLink to="/" exact activeClassName = "current">Home</NavLink>
          <hr/>
          <NavLink to="/systemconfig" exact activeClassName = "current">System Config</NavLink>
          <hr/>
          <NavLink to="/disinfectionconfig" exact activeClassName = "current"> Site Disinfection Config </NavLink>
          <hr/>
          <NavLink to="/siteinspectionconfig" exact activeClassName = "current"> Site Inspection Config </NavLink>
          <hr/>
          <NavLink to="/sitesecuritypatrolconfig" exact activeClassName = "current"> Site Security Patrol Config </NavLink>
          <hr/>
          <NavLink to="/receptionistconfig" exact activeClassName = "current">Receptionist Config </NavLink>
          <hr/>
          <NavLink to="/reporting" exact activeClassName = "current">Reporting </NavLink>
          {props.user.sysadmin ? <NavLink to="/createtenant" exact activeClassName = "current"><hr/>Create Tenant </NavLink> : null}
          {props.user.sysadmin ? <NavLink to="/createsite" exact activeClassName = "current"><hr/>Create Site </NavLink> : null}
          {props.user.sysadmin ? <NavLink to="/devpage" exact activeClassName = "current"><hr/>Dev Page </NavLink> : null}
        </div>
      </div>
      <div className="mainWindow">
        <h1 className="App-title">Ava&#174; Facilities Manager Hub</h1>
        <hr/>
        <Route path="/" exact component={() => <Home sysadmin={props.user.sysadmin} />} />
        <Route path="/systemconfig" component={SystemConfig} />
        <Route path="/siteinspectionconfig" component={SiteInspectionConfig} />
        <Route path="/disinfectionconfig" component={DisinfectionConfig} />
        <Route path="/sitesecuritypatrolconfig" component={SecurityConfig} />
        <Route path="/receptionistconfig" component={ReceptionistConfig} />
        <Route path="/reporting" component={Reporting} />
        {props.user.sysadmin ? <Route path="/createtenant" component={CreateTenant} /> :null}
        {props.user.sysadmin ? <Route path="/createsite" component={CreateSite} />:null}
        {/* NOTE:
          Following <Route path="/devpage" component={DevPage}/> is purely for
          development purpose. Please remove it before realising the application.*/}
        <Route path="/devpage" component={DevPage}/>
      </div>
      </Router>
    )

}
export default Routes
